export const LightSVG = (props) => {

  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" className={props.dark ? 'hidden' : 'visible'}>
      <path d="M15 21.25C18.4518 21.25 21.25 18.4518 21.25 15C21.25 11.5482 18.4518 8.75 15 8.75C11.5482 8.75 8.75 11.5482 8.75 15C8.75 18.4518 11.5482 21.25 15 21.25Z" fill="white"/>
      <path d="M26.25 16.25H25C24.6685 16.25 24.3505 16.1183 24.1161 15.8839C23.8817 15.6495 23.75 15.3315 23.75 15C23.75 14.6685 23.8817 14.3505 24.1161 14.1161C24.3505 13.8817 24.6685 13.75 25 13.75H26.25C26.5815 13.75 26.8995 13.8817 27.1339 14.1161C27.3683 14.3505 27.5 14.6685 27.5 15C27.5 15.3315 27.3683 15.6495 27.1339 15.8839C26.8995 16.1183 26.5815 16.25 26.25 16.25Z" fill="white"/>
      <path d="M5 16.25H3.75C3.41848 16.25 3.10054 16.1183 2.86612 15.8839C2.6317 15.6495 2.5 15.3315 2.5 15C2.5 14.6685 2.6317 14.3505 2.86612 14.1161C3.10054 13.8817 3.41848 13.75 3.75 13.75H5C5.33152 13.75 5.64946 13.8817 5.88388 14.1161C6.1183 14.3505 6.25 14.6685 6.25 15C6.25 15.3315 6.1183 15.6495 5.88388 15.8839C5.64946 16.1183 5.33152 16.25 5 16.25Z" fill="white"/>
      <path d="M22.0752 9.17502C21.7647 9.16096 21.4706 9.03172 21.2502 8.81252C21.0174 8.57831 20.8867 8.2615 20.8867 7.93126C20.8867 7.60103 21.0174 7.28422 21.2502 7.05002L22.1377 6.16252C22.2498 6.03166 22.3877 5.92539 22.5427 5.85036C22.6978 5.77533 22.8667 5.73317 23.0389 5.72652C23.211 5.71987 23.3827 5.74888 23.5431 5.81172C23.7035 5.87456 23.8492 5.96988 23.971 6.0917C24.0928 6.21352 24.1882 6.35921 24.251 6.51961C24.3138 6.68002 24.3429 6.85168 24.3362 7.02383C24.3296 7.19598 24.2874 7.3649 24.2124 7.51998C24.1373 7.67506 24.0311 7.81296 23.9002 7.92502L23.0127 8.81252C22.89 8.93453 22.7434 9.02975 22.582 9.09215C22.4206 9.15455 22.2481 9.18276 22.0752 9.17502V9.17502Z" fill="white"/>
      <path d="M7.04982 24.2C6.88531 24.201 6.72223 24.1694 6.56994 24.1072C6.41764 24.045 6.27912 23.9534 6.16232 23.8375C5.92951 23.6033 5.79883 23.2865 5.79883 22.9563C5.79883 22.626 5.92951 22.3092 6.16232 22.075L7.04982 21.25C7.28895 21.0452 7.59654 20.9382 7.91114 20.9504C8.22573 20.9625 8.52416 21.0929 8.74677 21.3156C8.96939 21.5382 9.09981 21.8366 9.11196 22.1512C9.12411 22.4658 9.0171 22.7734 8.81232 23.0125L7.92482 23.9C7.68224 24.1082 7.36912 24.2156 7.04982 24.2V24.2Z" fill="white"/>
      <path d="M15 6.25C14.6685 6.25 14.3505 6.1183 14.1161 5.88388C13.8817 5.64946 13.75 5.33152 13.75 5V3.75C13.75 3.41848 13.8817 3.10054 14.1161 2.86612C14.3505 2.6317 14.6685 2.5 15 2.5C15.3315 2.5 15.6495 2.6317 15.8839 2.86612C16.1183 3.10054 16.25 3.41848 16.25 3.75V5C16.25 5.33152 16.1183 5.64946 15.8839 5.88388C15.6495 6.1183 15.3315 6.25 15 6.25Z" fill="white"/>
      <path d="M15 27.5C14.6685 27.5 14.3505 27.3683 14.1161 27.1339C13.8817 26.8995 13.75 26.5815 13.75 26.25V25C13.75 24.6685 13.8817 24.3505 14.1161 24.1161C14.3505 23.8817 14.6685 23.75 15 23.75C15.3315 23.75 15.6495 23.8817 15.8839 24.1161C16.1183 24.3505 16.25 24.6685 16.25 25V26.25C16.25 26.5815 16.1183 26.8995 15.8839 27.1339C15.6495 27.3683 15.3315 27.5 15 27.5Z" fill="white"/>
      <path d="M7.92489 9.17501C7.59696 9.17363 7.28271 9.04344 7.04989 8.81251L6.16239 7.92501C5.9576 7.68588 5.8506 7.37829 5.86275 7.06369C5.8749 6.7491 6.00532 6.45067 6.22793 6.22806C6.45055 6.00544 6.74898 5.87502 7.06357 5.86287C7.37817 5.85072 7.68576 5.95773 7.92489 6.16251L8.81239 7.05001C9.0452 7.28421 9.17588 7.60103 9.17588 7.93126C9.17588 8.26149 9.0452 8.57831 8.81239 8.81251C8.69559 8.92836 8.55707 9.02002 8.40477 9.08223C8.25247 9.14443 8.0894 9.17596 7.92489 9.17501Z" fill="white"/>
      <path d="M22.9499 24.2C22.622 24.1986 22.3077 24.0684 22.0749 23.8375L21.2499 22.95C21.1178 22.7125 21.0667 22.4385 21.1043 22.1693C21.1419 21.9002 21.2662 21.6506 21.4584 21.4585C21.6506 21.2663 21.9001 21.142 22.1692 21.1044C22.4384 21.0668 22.7124 21.1179 22.9499 21.25L23.8374 22.1375C24.0702 22.3717 24.2009 22.6885 24.2009 23.0187C24.2009 23.349 24.0702 23.6658 23.8374 23.9C23.5916 24.1113 23.2734 24.2188 22.9499 24.2Z" fill="white"/>
    </svg>
    
  )
}