export const DribbbleSVG = () => {

  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05888 27.9411 0 18 0C8.05888 0 0 8.05888 0 18C0 27.9411 8.05888 36 18 36Z" fill="white"/>
      <path d="M17.9997 6.56885C11.6885 6.56885 6.55403 11.6973 6.55403 18.0004C6.55403 24.3035 11.6885 29.4316 17.9997 29.4316C24.3111 29.4316 29.446 24.3038 29.446 18.0004C29.446 11.6973 24.3111 6.56885 17.9997 6.56885ZM25.5314 11.9642C26.837 13.5824 27.6305 15.626 27.6671 17.8528C27.2326 17.7636 25.3928 17.4206 23.1942 17.4206C22.4848 17.4206 21.7381 17.4562 20.9895 17.5486C20.9263 17.3949 20.8631 17.2418 20.7967 17.0872C20.6026 16.6306 20.3933 16.1775 20.1768 15.7312C23.5606 14.337 25.1929 12.4022 25.5314 11.9642ZM17.9997 8.35832C20.4386 8.35832 22.668 9.26445 24.3708 10.7568C24.1013 11.1245 22.6394 12.9519 19.3765 14.1846C17.8662 11.4239 16.2131 9.13771 15.8197 8.607C16.5208 8.44464 17.2505 8.35832 17.9997 8.35832ZM13.8461 9.29525C14.1794 9.75442 15.8351 12.065 17.3843 14.8036C13.2268 15.8965 9.53488 15.9677 8.64383 15.9677H8.54918C9.18931 13.0176 11.1825 10.5662 13.8461 9.29525ZM8.32971 18.0155C8.32971 17.9359 8.33099 17.8563 8.33356 17.7771C8.39131 17.7777 8.47666 17.7777 8.58736 17.7777C9.7874 17.7777 13.9529 17.6785 18.2455 16.4047C18.506 16.9142 18.754 17.4315 18.9847 17.9484C18.8763 17.9795 18.7694 18.0106 18.6632 18.0453C13.83 19.6063 11.1736 23.7388 10.7648 24.4142C9.25027 22.7078 8.32971 20.4675 8.32971 18.0155ZM17.9997 27.672C15.7818 27.672 13.735 26.9208 12.1018 25.6614C12.3781 25.1198 14.3832 21.5097 19.6797 19.6663C19.6829 19.665 19.6865 19.6638 19.6903 19.6628C21.0184 23.1205 21.5809 26.0224 21.7352 26.9218C20.5859 27.405 19.3236 27.672 17.9997 27.672ZM23.4769 25.9698C23.3472 25.2257 22.8262 22.506 21.6235 19.1889C22.298 19.0839 22.9542 19.0426 23.5696 19.0426C25.5907 19.0426 27.1717 19.485 27.5391 19.5973C27.1024 22.2313 25.5943 24.5108 23.4769 25.9698Z" fill="url(#dribbble_linear)"/>
      <defs>
      <linearGradient id="dribbble_linear" x1="6.3" y1="6.30012" x2="28.8" y2="29.7001" gradientUnits="userSpaceOnUse">
      <stop id="dribbbleLinearStart" stopColor="#050505"/>
      <stop id="dribbbleLinearEnd" offset="1" stopColor="#050505"/>
      </linearGradient>
      </defs>
    </svg>
  )
}