export const MentorSVG = () => {

  return (
    <svg width="40" height="48" viewBox="0 0 40 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M37.2 36H4.7964C3.9216 36 3.1092 36.2544 2.4 36.6684V4.8C2.4 3.4776 3.4776 2.4 4.7964 2.4H37.2V36ZM37.2 40.8H8.4V43.2H37.2V45.6H4.7964C3.8952 45.6 3.1176 45.0912 2.7084 44.3532C2.5188 44.0076 2.4 43.6152 2.4 43.188V40.812C2.4 39.4812 3.48 38.4 4.7964 38.4H37.2V40.8ZM39.6 0V48H4.7964C2.8812 48 1.242 46.872 0.474 45.2436C0.1776 44.6208 0 43.9332 0 43.2V4.8C0 2.1492 2.1576 0 4.7964 0H39.6ZM19.1652 9.942L13.1652 13.542C12.4428 13.9752 12 14.7576 12 15.6V22.8C12 23.6424 12.4428 24.4248 13.1652 24.858L19.1652 28.458C19.9248 28.914 20.8752 28.914 21.6348 28.458L27.6348 24.858C28.3572 24.4248 28.8 23.6424 28.8 22.8V15.6C28.8 14.7576 28.3572 13.9752 27.6348 13.542L21.6348 9.942C20.8752 9.486 19.9248 9.486 19.1652 9.942ZM20.4 12L26.4 15.6V22.8L20.4 26.4L14.4 22.8V15.6L20.4 12Z" fill="url(#paint0_linear)" />
      <defs>
        <linearGradient id="paint0_linear" x1="0" y1="0" x2="40" y2="48" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7722FF" />
          <stop offset="1" stopColor="#CC11BB" />
        </linearGradient>
      </defs>
    </svg>
  )
}

